/* eslint-disable @typescript-eslint/no-explicit-any */
import http from "@/http";

class DisciplinaService {
  private endpoint = "disciplinas";

  list(): Promise<any> {
    return http.get(`${this.endpoint}`);
  }

  listSelect(): Promise<any> {
    return http.get(`${this.endpoint}/list-select`);
  }

  find(id: string): Promise<any> {
    return http.get(`${this.endpoint}/${id}`);
  }

  create(data: any): Promise<any> {
    return http.post(`${this.endpoint}`, data);
  }

  update(id: string, data: any): Promise<any> {
    return http.put(`${this.endpoint}/${id}`, data);
  }

  delete(id: string): Promise<any> {
    return http.delete(`${this.endpoint}/${id}`);
  }

  listByCurso(cursoId: string): Promise<any> {
    return http.get(`${this.endpoint}/by-curso/${cursoId}`);
  }
}

export default new DisciplinaService();
