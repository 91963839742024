/* eslint-disable @typescript-eslint/no-explicit-any */
import http from "@/http";

class ContaService {
  private endpoint = "contas";

  get(): Promise<any> {
    return http.get(`${this.endpoint}`);
  }

  update(data: any): Promise<any> {
    return http.put(`${this.endpoint}`, data);
  }

  senha(data: any): Promise<any> {
    return http.put(`${this.endpoint}/senha`, data);
  }
}

export default new ContaService();
